import {Save,Obtener,Editar} from '@/Generico/Conexion';
import ResponseGenerico from '../ResponseGenerico';
import { mainAxios } from '@/plugins/axios'
class Tarifa
{
    tarifaId:number =0;
    descripcion:string ="";
    origen:string ="";
    destino:string= "";
    valor:number =0;
    estado:boolean = true;
    constructor(tarifaId:number,descripcion:string,origen:string,destino:string,valor:number,estado:boolean)
    {
        this.tarifaId = tarifaId;
        this.descripcion = descripcion;
        this.origen = origen;
        this.destino = destino;
        this.valor  = valor;
        this.estado = estado
    }
}

async function  GuardarTarifa(tarifa:Tarifa):Promise<ResponseGenerico>
{
    return await Save<Tarifa>("Tarifa/Guardar",tarifa,mainAxios);
} 

async function  EditarTarifa(tarifa:Tarifa):Promise<ResponseGenerico>
{
    return await Editar<Tarifa>("tarifa/Editar",tarifa,mainAxios);
} 

async function  EditarEstadoTarifa(tarifa:Tarifa):Promise<ResponseGenerico>
{
    return await Editar<Tarifa>("tarifa/EditarEstado",tarifa,mainAxios);
} 

async function ObtenerTarifa()
{
    return await Obtener<Tarifa>("tarifa/Obtener",mainAxios);
}


async function ObtenerSelectTarifa()
{
    return await Obtener<Tarifa>("tarifa/ObtenerSelect",mainAxios);
}

export 
{
 Tarifa,
 ObtenerTarifa,
 ObtenerSelectTarifa,
 GuardarTarifa,
 EditarTarifa,
 EditarEstadoTarifa
}