












































import {EditarTarifa, GuardarTarifa, Tarifa} from '@/entidades/Plantacion/Tarifa';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
import {Vue,Component,Prop} from 'vue-property-decorator'
import {Validate, Validations} from 'vuelidate-property-decorators';
import {required,numeric,minValue,email} from 'vuelidate/lib/validators'
import { Action } from 'vuex-class';
@Component
export default class FormularioAddTarifa extends Vue
{
  @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
  @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;

  @Prop({required:true,type:Number})
  action!:number;
  @Prop({required:false})
  modelo!:Tarifa;

  descripcion:string="";
  origen:string ="";
  destino : string ="";
  valor : number = 0;
  tarifaId: number = 0;
  estado : boolean =true;

  //Configuracion de validacion
  @Validations()
     validations = {
         origen : {required},
         destino : {required},
         valor : {required,numeric},
     }

 async guardar()
 {
     try
     {
         this.changeLoading(new Loading(true,"Registrando informacion"));
         let response = new ResponseGenerico(false,"");
         let descripcion = `${this.origen}-${this.destino}`
         let tarifa:Tarifa = new Tarifa(this.tarifaId,descripcion,this.origen,this.destino,this.valor,true);
         console.log(tarifa);
         response = this.action == 1 ?  await GuardarTarifa(tarifa) : await EditarTarifa(tarifa);
         if(response.isSuccess == true)
         {
             this.$emit('limpiar');
         }
         else
         {
            this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
         }
     }
     catch(error)
     {
         //@ts-ignore
          this.changeAlerta(new Alerta(error,true,"danger",3000));
     }
     finally
     {
        this.changeLoading(new Loading(false,""));
     }
    
 }


 get mensaje()
 {
     if(this.action == 1)
        return {'titulo' : "REGISTRO DE TARIFA" , 'boton' : "GUARDAR"};
    else
        return {'titulo' : "EDICION DE TARIFA" , 'boton' : "EDITAR"};
 }

 mounted() {
    if(this.modelo!=undefined)
    {
        this.descripcion = this.modelo.descripcion;
        this.tarifaId = this.modelo.tarifaId;
        this.destino = this.modelo.destino;
        this.origen = this.modelo.origen;
        this.estado = this.modelo.estado;
        this.valor = this.modelo.valor;

    }   
 }

}
