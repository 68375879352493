






































import {Vue,Component} from 'vue-property-decorator';
import TablaGeneral from '@/components/General/TablaGeneral.vue';
import Formulario from '@/components/Maestro/Tarifa/Formulario.vue';
import { EditarEstadoTarifa, ObtenerTarifa, Tarifa } from '@/entidades/Plantacion/Tarifa';
import {Mutation} from 'vuex-class'
import Loading from '@/entidades/Sistema/Loading'
import Alerta from '@/entidades/Sistema/Alerta'

@Component({
    components : {
        TablaGeneral,
        Formulario
    }
})
export default class TarifaView extends Vue
{
    @Mutation('mostrarLoading',{namespace:'sistemaModule'}) mostrarLoading!:Function;
    @Mutation('mostarAlert',{namespace:'sistemaModule'}) mostarAlert!:Function;

    header:{text:string,sortable:boolean,value:string}[] =[
            {text:"Descripcion",sortable:true,value:'descripcion'},
            {text:"Origen",sortable:true,value:'origen'},
            {text:"Destino",sortable:true,value:'destino'},
            {text:"Valor",sortable:false,value:'valor'},
            {text:"Editar",sortable:false,value:'actions'},
            {text:"Estado",sortable:true,value:'estado'},
    ];
    listado:Array<Tarifa> =[];
    search:string ="";
    currentComponent:string="";
    modalAgregar:boolean = false;
    action:number  =1;
    modelo?:Tarifa;

    abrilModal()
    {
        this.action = 1;
        this.modelo = undefined;
        this.currentComponent = "Formulario";
        this.modalAgregar = true;
    }
    modalEditar(tarifa:Tarifa)
    {
        this.action = 2;
        this.modelo = tarifa;
        this.currentComponent = "Formulario";
        this.modalAgregar = true;
    }
    limpiar()
    {
        this.action = 1;
        this.modalAgregar = false;
        this.currentComponent = "";
        this.obtener();
    }
    async editarEstado(tarifa:Tarifa)
    {
        try
        {
            let response = await EditarEstadoTarifa(tarifa);
            if(response.isSuccess == true)
            {
                this.limpiar();
            }
            this.mostarAlert(new Alerta(response.isMessage,true,"primary",2000));            
        }
        catch(error)
        {
            console.log(error);
        }
    }

    async obtener()
    {
        try
        {
            this.mostrarLoading(new Loading(true,"Obteniendo Registros"));
            let response = await ObtenerTarifa();
            if(response.isSuccess == true)
            {
                this.listado = response.isResult;
            }
            else
            {
                this.mostarAlert(new Alerta(response.isMessage,true,"danger",2000));
            }
        }
        catch(error)
        {
               //@ts-ignore
            this.mostarAlert(new Alerta(error,true,"danger",2000));

        }finally{
            this.mostrarLoading(new Loading(false,""));
        }

    }

    get getPropiedades()
    {
        if(this.currentComponent == "Formulario")
        {
            return {'action' :this.action, 'modelo' :this.modelo};
        }
    }


    mounted()
    {
        this.obtener();
    }
}
